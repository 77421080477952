<template>
  <div class="columns is-multiline">
    <div class="column is-3" />
    <div class="column is-6">
      <div class="level checkout-header">
        <div class="level-left">
          <img
            src="../../../public/images/logo-rise.png"
            class="checkout-logo"
          />
          <span>
            <h2 class="checkout-subtitle">Checkout</h2>
          </span>
        </div>
        <div class="level-right">
          <b-button
            icon-left="cart"
            :icon-right="modalDetailOpen ? 'chevron-up' : 'chevron-down'"
            type="is-primary"
            outlined
            class="detail-button"
            @click.native="openModalDetail"
          >
            Rp. {{ formatCurrency(order.total) }}
            <r-card v-if="modalDetailOpen" class="modal-detail">
              <div class="column is-12 has-text-left modal-detail__title">
                Order Summary
              </div>
              <div class="columns is-multiline mt-3 package-container">
                <div class="is-2 pt-1 pl-5">
                  <img
                    src="../../../public/images/logo-rise.png"
                    class="checkout-logo"
                  />
                </div>
                <div class="is-10">
                  <div
                    class="is-12 has-text-left modal-detail__package-name is-capitalized"
                  >
                    {{ order.package }} Package
                  </div>
                  <div
                    class="is-12 has-text-left modal-detail__package-billing"
                  >
                    Billed {{ order.timeFrame }}
                  </div>
                </div>
              </div>
              <r-card class="package-detail-card">
                <div v-for="(item, index) in items" :key="index">
                  <div class="level pt-3 mb-0">
                    <div class="level-left is-capitalized">
                      {{ item.name }}
                    </div>
                    <div class="level-right">
                      Rp. {{ formatCurrency(item.price) }}
                    </div>
                  </div>
                </div>
                <hr
                  style="
                    border: 1px dashed #dfdfdf;
                    margin-top: 20px;
                    margin-bottom: 15px;
                    background-color: transparent;
                  "
                />
                <div class="level">
                  <div class="level-left">Subtotal</div>
                  <div class="level-right">
                    Rp. {{ formatCurrency(order.total) }}
                  </div>
                </div>
              </r-card>
            </r-card>
          </b-button>
        </div>
      </div>
      <hr style="background-color: #c5c7d0; border-width: 1px" />
      <!-- <shipping-details-info
        :phoneNumber="phoneNumber"
        :firstName="firstName"
        :lastName="lastName"
        :email="email"
        @input="setPhoneNumber($event)"
      ></shipping-details-info> -->
      <validation-observer v-slot="{ handleSubmit }" ref="observer">
        <form @submit.prevent="handleSubmit(checkout)">
          <div class="columns is-multiline checkout-header">
            <h2 class="column is-12 checkout-title">Shipping Details</h2>
            <div class="column is-12 pb-0">
              <div class="box checkout-form-vertical top">
                <validation-provider
                  name="phone number"
                  rules="e.164"
                  v-slot="{ errors }"
                >
                  <b-field
                    :message="errors[0]"
                    :type="errors[0] && 'is-danger'"
                  >
                    <vue-tel-input
                      placeholder="Enter a phone number"
                      :value="form.phone"
                      @input="setPhoneNumber"
                      :preferredCountries="['id', 'us', 'gb']"
                      mode="international"
                      @open="open = true"
                      @close="open = false"
                      readonly="snapTokenExists"
                    >
                      <template v-slot:arrow-icon>
                        <span>{{ open ? '▲' : '▼' }}</span>
                      </template>
                    </vue-tel-input>
                  </b-field>
                </validation-provider>
              </div>
            </div>
            <div class="column is-6 py-0 pr-0">
              <div class="box checkout-form-horizontal">
                <validation-provider
                  name="first name"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-field
                    :message="errors[0]"
                    :type="errors[0] && 'is-danger'"
                  >
                    <b-input
                      placeholder="First Name"
                      v-model="form.firstName"
                      :readonly="snapTokenExists"
                    />
                  </b-field>
                </validation-provider>
              </div>
            </div>
            <div class="column is-6 py-0 pl-0">
              <div class="box checkout-form-horizontal">
                <validation-provider
                  name="last name"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-field
                    :message="errors[0]"
                    :type="errors[0] && 'is-danger'"
                  >
                    <b-input
                      placeholder="Last Name"
                      v-model="form.lastName"
                      :readonly="snapTokenExists"
                    />
                  </b-field>
                </validation-provider>
              </div>
            </div>
            <div class="column is-12 py-0">
              <div class="box checkout-form-vertical bottom">
                <validation-provider
                  name="email"
                  rules="required|email"
                  v-slot="{ errors }"
                >
                  <b-field
                    :message="errors[0]"
                    :type="errors[0] && 'is-danger'"
                  >
                    <b-input
                      placeholder="Email"
                      v-model="form.email"
                      :readonly="snapTokenExists"
                    />
                  </b-field>
                </validation-provider>
              </div>
            </div>
          </div>
          <AvailablePaymentMethods />
          <div class="columns is-multiline">
            <div class="column is-12">
              <div class="box payment-information">
                <b-input
                  placeholder="Enter order notes here (optional)"
                  v-model="form.notes"
                  :readonly="snapTokenExists"
                ></b-input>
              </div>
              <div class="column is-12">
                <b-checkbox v-model="saveInfo" class="save-info">
                  Save my info with Rise Payment
                </b-checkbox>
              </div>
            </div>
          </div>
          <div class="column is-3" />
          <div class="column is-12 footer">
            <div class="columns is-multiline">
              <div class="column is-4" />
              <div class="column is-4">
                <b-button
                  expanded
                  type="is-primary"
                  style="margin-top: -18px"
                  :disabled="!saveInfo"
                  native-type="submit"
                >
                  {{ 'Confirm & Pay' }}
                </b-button>
                <div class="is-12 pt-3 has-text-centered">
                  <a
                    href="https://getrise.id/terms-and-conditions"
                    class="tos-link"
                  >
                    By clicking Pay you agree to our Terms and Privacy Policy
                  </a>
                </div>
              </div>
              <div class="is-4" />
            </div>
          </div>
        </form>
      </validation-observer>
    </div>
  </div>
</template>

<script>
// import ShippingDetailsInfo from '../../components/ShippingDetailsInfo.vue'
import AvailablePaymentMethods from '../../components/AvailablePaymentMethods'
import router from '@/router'
export default {
  components: { AvailablePaymentMethods },
  data() {
    return {
      form: {
        orderId: this.$route.query.order,
        phone: null,
        firstName: null,
        lastName: null,
        email: null,
        notes: null,
      },
      items: [],
      order: {
        product: null,
        package: null,
        total: 0,
        timeFrame: 'monthly',
      },
      snapToken: null,
      open: false,
      saveInfo: false,
      modalDetailOpen: false,
    }
  },

  computed: {
    // a computed getter
    snapTokenExists() {
      return !!this.snapToken
    },
  },

  methods: {
    setPhoneNumber(event) {
      this.form.phone = event.replace(/\s+/g, '')
    },
    openModalDetail() {
      this.modalDetailOpen = !this.modalDetailOpen
    },
    getPaymentDetail() {
      this.$store
        .dispatch('payments/getPaymentDetail', this.form.orderId)
        .then((response) => {
          let data = response.data
          if (data.buyer) {
            this.form.firstName = data.buyer.firstName
            this.form.lastName = data.buyer.lastName
            this.form.phone = data.buyer.phone
            this.form.email = data.buyer.email
          }
          this.form.notes = data.notes
          this.order.product = data.product
          this.order.package = data.package
          this.order.total = data.totalAmount
          this.items = data.items
          this.snapToken = data.snapToken
          if (this.snapTokenExists) {
            this.checkout()
          }
        })
        .catch((error) => {
          if (error.response?.status === 404) {
            return router.push({ name: 'NotFound' })
          }
          this.$swal({
            icon: 'error',
            title: ' Error !',
            text: `Failed to retrieve payment data. Please try again.`,
            confirmButtonText: 'OK',
            customClass: {
              confirmButton: 'button is-danger',
            },
          }).then((result) => {
            if (result['isConfirmed'] || result['isDismissed']) {
              router.push({ path: '/auth/login' })
            }
          })
        })
    },
    async checkout() {
      if (!this.snapToken) {
        await this.$store
          .dispatch('payments/checkout', this.form)
          .then((response) => {
            this.snapToken = response.token
          })
          .catch((error) => {
            this.$emit('close')
            console.log(error)
            this.$swal({
              icon: 'error',
              title: 'Payment Error !',
              text: `Failed to connect to the payment service. Please try again.`,
              confirmButtonText: 'OK',
              customClass: {
                confirmButton: 'button is-danger',
              },
            })
          })
      }

      window.snap.pay(this.snapToken, {
        onSuccess: () => {
          console.debug('payment success')
          this.$router.push(`/payment-success?order=${this.form.orderId}`)
        },
        onPending: () => {
          console.debug('payment pending')
        },
        onError: (result) => {
          console.log('payment error')
          console.log(result)
          this.$swal({
            icon: 'error',
            title: 'Payment Error !',
            text: `Status Code : ${result.status_code}. ${result.status_message}. Please try again.`,
            confirmButtonText: 'OK',
            customClass: {
              confirmButton: 'button is-danger',
            },
          })
        },
      })
    },
    formatCurrency(val) {
      return new Intl.NumberFormat('id-ID', {
        minimumFractionDigits: 0,
      }).format(val)
    },
  },
  mounted() {
    if (this.form.orderId) {
      this.getPaymentDetail()
    } else {
      return router.push({ name: 'NotFound' })
    }

    const midtrans = document.createElement('script')
    midtrans.setAttribute('src', process.env.VUE_APP_MIDTRANS_SCRIPT_URL)
    midtrans.setAttribute(
      'data-client-key',
      process.env.VUE_APP_MIDTRANS_CLIENT_KEY
    )
    midtrans.async = true
    document.head.appendChild(midtrans)
  },
}
</script>
